import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"
import Cards from "../components/cards"
import  CommingSoon from "../components/comming-soon"
import HeaderAndText from '../components/header_and_text'
import ImageAndButton from '../components/image_and_button'
import ImageSlider from "../components/image-slider"
import Calander from "../components/calander"
import { Container } from "@material-ui/core"
import ClipLoader from "react-spinners/ClipLoader"
import { useState } from "react"
import { useEffect } from "react"
import { css } from "@emotion/react"
import MiddleVideo from "../components/middle-video"
import { useContext } from "react"
import ApiContext from "../providers/api"
const spinner = css`
  display: block;
  margin: 0 auto;
`;

const LiveStream = () => {
  const { getStreamLink, isLoading } = useContext(ApiContext);
  const [streamlink, setStreamlink] = useState('');

  const failHTML = (
    <Layout pageName="Live stream" noMargin liveStream>
    <Seo title="Live stream" />
    <Container maxWidth="xl">
      <br />
      <br />
      <h2 class="h1 error">Sorry Wrong Password!</h2>
      <br />
      <br />
    </Container>
  </Layout>
  );

  const successHTML = (
    <Layout pageName="Live stream" noMargin liveStream>
      <Seo title="Live stream" />
      <Container maxWidth="xl">
          <MiddleVideo id={streamlink} />
      </Container>
      
      <HeaderAndText />
      <Calander />
    </Layout>
  );

  const [pageHTML, setPageHTML] = useState(
    <Layout pageName="Live stream" noMargin liveStream>
      <br />
      <br />
      <div className="loaderWrapper">
        <ClipLoader
            css={spinner}
            size={150}
            color={"#fada4d"}
        />
      </div>
      <br />
      <br />
    </Layout>
  );

  useEffect(async () => {
    const pass = prompt("Please Enter Stream Password!");
    if(pass == 'asd') {
      const linkRes = await getStreamLink();
      
      setPageHTML(<Layout pageName="Live stream" noMargin liveStream>
      <Seo title="Live stream" />
      <Container maxWidth="xl">
          <MiddleVideo id={linkRes.VideoStreamId} />
      </Container>
      
      <HeaderAndText />
      <Calander />
    </Layout>);
    } else {
      setPageHTML(failHTML);
    }
  }, []);

  return pageHTML;
}

export default LiveStream
